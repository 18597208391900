import Vue from 'vue'

class VatRateRepository {

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/pricing/vat-rate/get/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  create(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/pricing/vat-rate/create',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  update(id, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/pricing/vat-rate/update/' + id,
        method: 'put',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  search(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/pricing/vat-rate/search',
        method: 'post',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

    delete(id) {
      return new Promise((resolve, reject) => {
        Vue.auth.fetch({
          url: `/api/pricing/vat-rate/delete/${id}`,
          method: 'delete',
        }).then(response => {
          resolve(response.data)
        }).catch(err => {
          reject(err)
        })
      })
    }
}

export default new VatRateRepository()