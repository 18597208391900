<template>
  <div class="vat-rate-form pa-10">



    <title-page icon="mdi-percent" class="mb-0">
      {{ $t("vat-rate.add") }}
    </title-page>

    <div class="mt-5">
      <headline>{{ $tc('vat-rate.vat-rate') }}</headline>
      <v-row>
        <v-col cols="12">
          <v-form ref="form" @submit.prevent="save">
            <v-row>
              <v-col cols="6">
                <form-field
                    type="available-select"
                    input-type="country"
                    :label="$t('country')"
                    v-model="form.country"
                    :rules="[
                        commonRules.required,
                    ]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <form-field
                    type="date"
                    :label="$t('vat-rate.effective-date')"
                    v-model="form.effectiveDate"
                    :rules="[
                        commonRules.required,
                    ]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <form-field
                    input-type="number"
                    :label="$t('vat-rate.charging-rate')"
                    v-model.number="form.chargingRate"
                    :rules="[
                        commonRules.required,
                    ]"
                >
                  <template #append>%</template>
                </form-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <form-field
                    input-type="number"
                    :label="$t('vat-rate.refund-rate')"
                    v-model.number="form.refundRate"
                    :rules="[
                        commonRules.required,
                    ]"
                >
                  <template #append>%</template>
                </form-field>
              </v-col>
            </v-row>

            <v-row class="mt-10">
              <v-col cols="12" class="text-center">
                <v-btn type="submit" color="primary">
                  {{ $t('save') }}
                </v-btn>
              </v-col>
            </v-row>

          </v-form>


          <v-divider class="my-10"></v-divider>

          <v-row>
            <v-col class="text-center">
              <v-btn small @click="deleteItem">
                {{ $t('delete') }}
              </v-btn>
            </v-col>
          </v-row>

        </v-col>
      </v-row>

    </div>

  </div>
</template>

<script>


import CustomerRepository from "@repository/CustomerRepository";
import RulesMixin from "@mixins/RulesMixin";
import PriceIndexRepository from "@repository/PriceIndexRepository";
import VatRateRepository from "@repository/VatRateRepository";
import dayjs from "dayjs";

export default {
  components: {

  },
  data() {
    return {
      form: {
        effectiveDate: dayjs().format('YYYY-MM-DD'),
      },
    }
  },

  mixins: [
    RulesMixin,
  ],


  mounted() {
    if(!this.currentUser.permissions.includes('OPERATOR_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }

    if(this.isEdit) {
      this.loadData()
    }

  },

  watch: {
    form: {
      handler() {
        this.setUnsavedChanges()
      },
      deep: true
    }
  },

  computed: {
    id() {
      return this.$route.params.id ?? null
    },

    isEdit() {
      return !!this.id
    }
  },

  methods: {


    cancel() {

    },


    loadData() {
      VatRateRepository.get(this.id).then(result => {
        this.form = result
        this.$nextTick(() => {
          this.cleanUnsavedChanges()
        })
      }).catch(err => {
        this.notifyError(err)
      })
    },

    save() {

      if(this.$refs.form.validate()) {
        this.showLoading(true)

        if(this.isEdit) {
          VatRateRepository.update(this.id, this.form).then(response => {
            this.showLoading(false)
            this.$dialog.notify.success(this.$t('update-success'))
            this.cleanUnsavedChanges()
            this.$router.push({name: 'vat-rates'})
          }).catch(err => {
            this.notifyError(err)
          })
        } else {
          VatRateRepository.create(this.form).then(response => {
            this.showLoading(false)
            this.cleanUnsavedChanges()
            this.$dialog.notify.success(this.$t('created-successfully'))
            this.$router.push({name: 'vat-rates'})
          }).catch(err => {
            this.notifyError(err)
          })
        }

      }

    },

    deleteItem() {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          VatRateRepository.delete(this.id).then(result => {
            this.showLoading(false)
            this.$dialog.notify.success(this.$t('delete-success'))
            this.$router.replace({name: 'vat-rates'})
          }).catch(err => {
            this.notifyError(err)
            this.showLoading(false)
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.vat-rate-form {

}
</style>