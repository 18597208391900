var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vat-rate-form pa-10"},[_c('title-page',{staticClass:"mb-0",attrs:{"icon":"mdi-percent"}},[_vm._v(" "+_vm._s(_vm.$t("vat-rate.add"))+" ")]),_c('div',{staticClass:"mt-5"},[_c('headline',[_vm._v(_vm._s(_vm.$tc('vat-rate.vat-rate')))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('form-field',{attrs:{"type":"available-select","input-type":"country","label":_vm.$t('country'),"rules":[
                      _vm.commonRules.required,
                  ]},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('form-field',{attrs:{"type":"date","label":_vm.$t('vat-rate.effective-date'),"rules":[
                      _vm.commonRules.required,
                  ]},model:{value:(_vm.form.effectiveDate),callback:function ($$v) {_vm.$set(_vm.form, "effectiveDate", $$v)},expression:"form.effectiveDate"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('form-field',{attrs:{"input-type":"number","label":_vm.$t('vat-rate.charging-rate'),"rules":[
                      _vm.commonRules.required,
                  ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v("%")]},proxy:true}]),model:{value:(_vm.form.chargingRate),callback:function ($$v) {_vm.$set(_vm.form, "chargingRate", _vm._n($$v))},expression:"form.chargingRate"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('form-field',{attrs:{"input-type":"number","label":_vm.$t('vat-rate.refund-rate'),"rules":[
                      _vm.commonRules.required,
                  ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v("%")]},proxy:true}]),model:{value:(_vm.form.refundRate),callback:function ($$v) {_vm.$set(_vm.form, "refundRate", _vm._n($$v))},expression:"form.refundRate"}})],1)],1),_c('v-row',{staticClass:"mt-10"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"type":"submit","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1),_c('v-divider',{staticClass:"my-10"}),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"small":""},on:{"click":_vm.deleteItem}},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }