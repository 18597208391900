<template>
  <div class="vat-rate-search pa-10">

    <title-page icon="mdi-percent">
      {{ $tc('vat-rate.vat-rate', 2) }}
    </title-page>

    <v-row v-if="currentUser.permissions.includes('OPERATOR_WRITE')">
      <v-col class="text-right">
        <v-btn small :to="{name: 'vat-rates.add'}">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('vat-rate.add')}}
        </v-btn>
      </v-col>
    </v-row>


    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          :server-items-length="total"
        >

          <template #item.effectiveDate="{item}">
            {{ item.effectiveDate | formatDate($t('format_date')) }}
          </template>

          <template #item.chargingRate="{value}">
            {{ value }} %
          </template>

          <template #item.refundRate="{value}">
            {{ value }} %
          </template>

          <template #item.options="{item}">
            <v-btn icon :to="{name: 'vat-rates.edit', params: {id: item.id}}">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="deleteVatRate(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>






  </div>
</template>

<script>

import VatRateRepository from "@repository/VatRateRepository";

export default {
  components: {

  },
  data() {
    return {
      loading: false,
      items: [],
      total: 0,
      options: null,

    }
  },

  mounted() {
    if(!this.currentUser.permissions.includes('OPERATOR_READ')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
  },

  watch: {

    options(val) {
      this.searchData()
    }
  },

  computed: {
    headers() {
      return [
        {text: this.$t('country'), value: 'country'},
        {text: this.$t('vat-rate.effective-date'), value: 'effectiveDate'},
        {text: this.$t('vat-rate.charging-rate'), value: 'chargingRate'},
        {text: this.$t('vat-rate.refund-rate'), value: 'refundRate'},
        {text: '', value: 'options', width: 200},
      ]
    },

  },

  methods: {
    searchData() {

      let options = this.options



      this.loading = true
      let offset = options.itemsPerPage * options.page - options.itemsPerPage;
      let limit = options.itemsPerPage;
      let orderBy = options.sortBy.length > 0 ? options.sortBy[0] : 'name'
      let orderDirection = options.sortDesc.length > 0 && options.sortDesc[0] ? 'desc' : 'asc'



      this.loading = true
      VatRateRepository.search({
        offset,
        limit,
        orderBy,
        orderDirection,
      }).then(result => {
        this.total = result.total
        this.items = result.items
        this.loading = false
      }).catch(err => {
        this.notifyError(err.message)
        this.loading = false
      })
    },

    deleteVatRate(vatRateId)  {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          VatRateRepository.delete(vatRateId).then(response => {
            this.$dialog.notify.success(this.$t('update-success'))
            this.showLoading(false)
            this.searchData();
          }).catch(err => {
            this.showLoading(false)
            this.notifyError(err)
          })
        }
      })
    },


  }
}
</script>

<style lang="scss">
.vat-rate-search {

}


</style>